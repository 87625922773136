// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    padding: none;
  }
  
  body {
    color: #658c5d;
    text-align: center;
    /* padding: 50px; */
    
    font: 14px "Elza", Helvetica, Arial, sans-serif;
  }

  
  .sources {
    text-align: left;
  }
  

  canvas {
    /* padding-left: 0;
    padding-right: 0; */
    /* padding-top: 5vh; */
    /* margin-left: auto;
    margin-right: auto; */
    display: block;
    position: relative;
  }
  .global-projections-canvas-container {
    display: inline-block;
  }

  .graticule path {
    fill: none;
    stroke: #eee;
  }
  `, "",{"version":3,"sources":["webpack://./src/MapProjection/MapProjections.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,mBAAmB;;IAEnB,+CAA+C;EACjD;;;EAGA;IACE,gBAAgB;EAClB;;;EAGA;IACE;uBACmB;IACnB,sBAAsB;IACtB;yBACqB;IACrB,cAAc;IACd,kBAAkB;EACpB;EACA;IACE,qBAAqB;EACvB;;EAEA;IACE,UAAU;IACV,YAAY;EACd","sourcesContent":["html {\n    padding: none;\n  }\n  \n  body {\n    color: #658c5d;\n    text-align: center;\n    /* padding: 50px; */\n    \n    font: 14px \"Elza\", Helvetica, Arial, sans-serif;\n  }\n\n  \n  .sources {\n    text-align: left;\n  }\n  \n\n  canvas {\n    /* padding-left: 0;\n    padding-right: 0; */\n    /* padding-top: 5vh; */\n    /* margin-left: auto;\n    margin-right: auto; */\n    display: block;\n    position: relative;\n  }\n  .global-projections-canvas-container {\n    display: inline-block;\n  }\n\n  .graticule path {\n    fill: none;\n    stroke: #eee;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
